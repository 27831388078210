export type Language = 'fi' | 'en';

export const translations: Record<Language, Record<string, string>> = {
    'fi': {
        'sections': 'Osat',
        'start': 'Aloita',
        'answer': 'Vastaa',
        'play_again': 'Pelaa uudelleen',
        'remove_selection': 'Poista valinta',
        'continue': 'Jatka',
        'choose_answer': 'Valitse vastaus',
        'close': 'Sulje',
        'fill_all_fields': 'Täytä kaikki kentät',
        'select_multiple_info': 'Valitse yksi tai useampi vaihtoehto',
        'select_single_info': 'Valitse yksi vaihtoehto',
        'order_info': 'Järjestä vaihtoehdot oikeaan järjestykseen',
        'connect_info': 'Yhdistä oikeat vaihtoehdot',
        'error_loading_game': 'Virhe ladattaessa peliä',
    },
    'en': {
        'sections': 'Sections',
        'start': 'Start',
        'answer': 'Answer',
        'play_again': 'Play again',
        'remove_selection': 'Remove selection',
        'continue': 'Continue',
        'choose_answer': 'Choose answer',
        'close': 'Close',
        'fill_all_fields': 'Fill all fields',
        'select_multiple_info': 'Select one or more options',
        'select_single_info': 'Select one option',
        'order_info': 'Order the options correctly',
        'connect_info': 'Connect the correct options',
        'error_loading_game': 'Error loading game',
    }
};