import React from 'react';
import { ReactComponent as DashBoardIcon } from '../../images/icons/gauge-regular.svg';
import { ReactComponent as GameIcon } from '../../images/icons/trophy-regular.svg';
import { ReactComponent as QuestionIcon } from '../../images/icons/lightbulb-regular.svg';

const SideBar = () => {

    return (
        <div className='admin-sidebar'>
            <div className='sidebar-inner px-4 pt-3'>
                <div className='flex-column nav'>
                    <div className='nav-item active'>
                        <a className='nav-link' href='#'>
                            <span className='sidebar-icon'><DashBoardIcon/></span>
                            <span>Dashboard</span>
                        </a>
                    </div>
                    <div className='nav-item'>
                        <a className='nav-link' href='#'>
                            <span className='sidebar-icon'><GameIcon/></span>
                            <span>Games</span>
                        </a>
                    </div>
                    <div className='nav-item'>
                        <a className='nav-link' href='#'>
                            <span className='sidebar-icon icon-question'><QuestionIcon/></span>
                            <span>Questions</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SideBar;