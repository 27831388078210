type Params = { [x: string]: string | number | boolean | string[] | number[] | boolean[]; };

const getCookie = (name: string) => {
    if (!document.cookie) {
        return '';
    }
    const xsrfCookies = document.cookie.split(';')
        .map(c => c.trim())
        .filter(c => c.startsWith(name + '='));
    if (xsrfCookies.length === 0) {
        return '';
    }
    return decodeURIComponent(xsrfCookies[0].split('=')[1]);
};

export const getQueryString = (params: Params) => {
    return Object
        .keys(params)
        .map(k => {
            if (Array.isArray(params[k])) {
                return (params[k] as unknown as (string[] | number[] | boolean[]))
                    .map((val: string | number | boolean) => `${encodeURIComponent(k)}=${encodeURIComponent(val)}`)
                    .join('&')
            } else {
                return `${encodeURIComponent(k)}=${encodeURIComponent(params[k] as string | number | boolean)}`
            }
        })
        .join('&')
};

const processJSONResponse = async (response: Response) => {
    if (response.ok) {
        if (response.status === 204) {
            // 204 NO CONTENT: Doesn't contain any return data.
            return;
        }
        return await response.json();
    } else {
        let error = new Error("Error");
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
            const json = await response.json();
            error.message = json.detail;
        } else {
            error.message = await response.text();
        }
        throw error;
    }
};

const processBlobResponse = async (response: Response) => {
    if (response.ok) {
        if (response.status === 204) {
            // 204 NO CONTENT: Doesn't contain any return data.
            return;
        }
        return await response.blob();
    } else {
        let error = new Error("Error");
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
            const json = await response.json();
            error.message = json.detail;
        } else {
            error.message = await response.text();
        }
        throw error;
    }
};

const makeRequest = async (url: string, options: RequestInit | undefined, responseType = "json") => {
    let response;
    try {
        response = await fetch(url, options);
    } catch (e) {
        let error = new Error("Error");
        error.message = (e as Error).message;
        throw error;
    }
    if (responseType === "blob") {
        return await processBlobResponse(response);
    } else {
        return await processJSONResponse(response);
    }
};

export const get = async (url: string, params: Params | null = null) => {
    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
        }
    };
    if (params !== null) {
        url = url + '?' + getQueryString(params);
    }
    return await makeRequest(url, options);
};


export const post = async (url: string, data: any, isUpload = false, params: Params | null = null) => {
    const csrfToken = getCookie('csrftoken');
    let headers: { [key: string]: string } = { 'X-CSRFToken': csrfToken };
    if (!isUpload) {
        headers['Accept'] = 'application/json';
        headers['Content-Type'] = 'application/json;charset=UTF-8';
    }
    const options = {
        method: 'POST',
        headers: headers,
        // file upload data will be wrapped in data = new FormData()
        body: isUpload ? data : JSON.stringify(data)
    };
    if (params !== null) {
        url = url + '?' + getQueryString(params);
    }
    return await makeRequest(url, options);
};

export const patch = async (url: string, data: any, isUpload = false, params: Params | null = null) => {
    const csrfToken = getCookie('csrftoken');
    let headers: { [key: string]: string } = { 'X-CSRFToken': csrfToken };
    if (!isUpload) {
        headers['Accept'] = 'application/json';
        headers['Content-Type'] = 'application/json;charset=UTF-8';
    }
    const options = {
        method: 'PATCH',
        headers: headers,
        // file upload data will be wrapped in data = new FormData()
        body: isUpload ? data : JSON.stringify(data)
    };
    if (params !== null) {
        url = url + '?' + getQueryString(params);
    }
    return await makeRequest(url, options);
};

export const put = async (url: string, data: any, isUpload = false) => {
    const csrfToken = getCookie('csrftoken');
    let headers: { [key: string]: string } = { 'X-CSRFToken': csrfToken };
    if (!isUpload) {
        headers['Accept'] = 'application/json';
        headers['Content-Type'] = 'application/json;charset=UTF-8';
    }
    const options = {
        method: 'PUT',
        headers: headers,
        // file upload data will be wrapped in data = new FormData()
        body: isUpload ? data : JSON.stringify(data)
    };
    return await makeRequest(url, options);
};

export const del = async (url: string, data?: any | undefined) => {
    const csrfToken = getCookie('csrftoken');
    let headers: { [key: string]: string } = { 'X-CSRFToken': csrfToken };
    headers['Accept'] = 'application/json';
    headers['Content-Type'] = 'application/json;charset=UTF-8';
    const options = {
        method: 'DELETE',
        headers: headers,
        body: typeof data !== "undefined" ? JSON.stringify(data) : undefined
    };
    return await makeRequest(url, options);
};

export const api = {
    get,
    post,
    put,
    patch,
    delete: del,
}