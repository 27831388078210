import React from 'react';
import { Spinner } from 'react-bootstrap';


interface LoaderProps {
  variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
}

const Loader: React.FC<LoaderProps> = ({ variant = 'primary' }) => {
  return (
        <div className='game-loading'>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
    </div>
  );
};

export default Loader;