import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../app/store";
import {clearError, continueGameSession, questionAnswer, startGameSession} from "../../app/slices/gameSession";
import QuestionContent from "./QuestionContent";
import {QuestionTypeConnect, QuestionTypeOrder, QuestionTypeSelect} from "../../types/gameSession";
import {Language, translations} from "../../translations";
import toast from "react-hot-toast";
import GameSlide from "./GameSlide";
import infoIcon from '../../images/icons/circle-info-regular.svg';

type Props = {
    game_id: number;
    lang: Language;
}

const GameSession = ({ game_id, lang }: Props) => {
    const dispatch = useAppDispatch();
    const { session_data, loaded, error, fade_out } = useAppSelector((state) => state.gameSession);
    const { data } = useAppSelector((state) => state.game);
    const LANG = translations[lang];

    useEffect(() => {
        // NOTE: on React.StrictMode, this useEffect is called twice on dev mode
        if (loaded) return;
        const form_fields = data?.form;
        dispatch(startGameSession({form_fields, game_id: game_id, language: lang}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loaded]);

    const handleContinue = () => {
        dispatch(continueGameSession())
    }

    let questionInfo = '';
    switch (session_data?.question?.type) {
        case 'select':
            const question = session_data.question as QuestionTypeSelect;
            if (question.multiple) {
                questionInfo = LANG.select_multiple_info;
            }else{
                questionInfo = LANG.select_single_info;
            }
            break;
        case 'order':
            questionInfo = LANG.order_info;
            break;
        case 'connect':
            questionInfo = LANG.connect_info;
            break;
        default:
            questionInfo = '';
    }

    const handleAnswer = () => {
        let payload: any = {
            content_id: session_data?.content_id,
        }

        switch (session_data?.question?.type) {
            case 'select':
                const question = session_data.question as QuestionTypeSelect;
                const selectedIndexes = question.options
                    .filter(option => option.selected)
                    .map(option => option.index);
                payload.answers = selectedIndexes;
                dispatch(questionAnswer(payload));
                break;
            case 'order':
                const orderQuestion = session_data.question as QuestionTypeOrder;
                payload.answers = orderQuestion.options.map(option => option.index);
                dispatch(questionAnswer(payload));
                break;
            case 'connect':
                const connectQuestion = session_data.question as QuestionTypeConnect;
                payload.answers = connectQuestion.selected_pairs;
                dispatch(questionAnswer(payload));
                break;
            default:
                if (session_data?.slide){
                    dispatch(questionAnswer(payload));
                }
        }
    };

    if ( error ) {
      toast.error(error, {position: 'top-right',});
      dispatch(clearError());
    }


    let content;
    if (session_data && session_data.question) {
        content = (
            <div
                className={`game-container ${session_data && session_data.question && session_data.question?.answered ? 'answered' : ''}`}>
                <div className='game-card-header'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-12 col-md-6 offset-md-3'>
                                <h3>{session_data.question.title}</h3>
                                <div className='description-wrapper'>
                                    <div className='description-text'
                                         dangerouslySetInnerHTML={{__html: session_data.question.description}}>
                                    </div>
                                    {questionInfo && (
                                        <div className='question-info'>
                                            <img src={infoIcon} alt=''/>
                                            <span>{questionInfo}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='game-card-body'>
                    <QuestionContent question={session_data.question} lang={lang}/>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-12 col-md-6 offset-md-3'>
                                <div className={`outro-text ${session_data.question.outro_text ? 'outro-visible' : ''}`}
                                     dangerouslySetInnerHTML={{__html: session_data.question.outro_text}}>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='game-card-footer'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-12 d-flex justify-content-end'>
                            {session_data.question.answered ? (
                            <button className='btn game-btn btn-primary' onClick={handleContinue}>{LANG.continue}</button>
                            ): (
                            <button className='btn game-btn btn-primary' onClick={handleAnswer}>{LANG.answer}</button>
                            )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (session_data && session_data.end) {
        content = (
        <div className='game-container'>
            <div className='game-card-header'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12'>
                            <h1>{session_data.end.title}</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className='game-card-body'>
                <p>{session_data.end.description}</p>
            </div>
            <div className='game-card-footer'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12 d-flex justify-content-end'>
                            <button className='btn btn-primary'
                                    onClick={() => window.location.reload()}>{LANG.play_again}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    } else if (session_data && session_data.slide) {
        content = (<GameSlide slide={session_data.slide} lang={lang} handleAnswer={handleAnswer}/>)
    } else {
        content = '';
    }


    return (
        <div className={`game-animation-container ${fade_out ? 'game-fade-out' : 'game-fade-in'}`}>
            {content}
        </div>
    )
}

export default GameSession;