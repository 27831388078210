import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../app/store";
import {getProfile} from "../../app/slices/profile";
import Loader from "../Loader";
import SideBar from "./SideBar";
import AdminTopBar from "./AdminTopBar";

const Admin = () => {
    const dispatch = useAppDispatch();
    const { loaded } = useAppSelector((state) => state.profile);
    useEffect(() => {
        // NOTE: on React.StrictMode, this useEffect is called twice on dev mode
        if (loaded) return;
        dispatch(getProfile());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loaded]);
    if (!loaded) return <Loader />;

    return (
        <div className='admin'>
            <AdminTopBar />
            <SideBar />
            <div className='admin-content'>
            </div>
        </div>
    )
}

export default Admin;