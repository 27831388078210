import {Language} from "../../translations";
import defaultLogo from "../../images/perjantai-logo.svg"
import {useAppSelector} from "../../app/store";
import {useEffect, useState} from "react";


type Props = {
    lang: Language;
    gameStarted: boolean;
}

const GameTopBar = ({ lang, gameStarted }: Props) => {
    const { session_data } = useAppSelector((state) => state.gameSession);
    const { data } = useAppSelector((state) => state.game);
    const [animatedSessionPoints, setSessionPoints] = useState(0);
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [headerVisible, setHeaderVisible] = useState(true);

    useEffect(() => {
        let intervalId: NodeJS.Timeout;

        if (session_data && session_data.points) {
            const points = session_data.points || 0;
            const increment = points / 100;
            let currentPoints = animatedSessionPoints;

            intervalId = setInterval(() => {
                currentPoints += increment;
                if (currentPoints >= points) {
                    setSessionPoints(points);
                    clearInterval(intervalId);
                } else {
                    setSessionPoints(currentPoints);
                }
            }, 10);

            return () => clearInterval(intervalId);
        }

        setSessionPoints(0);

        return () => clearInterval(intervalId);
    }, [session_data?.points]);


    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            const isScrollingDown = currentScrollPos > prevScrollPos;
            setHeaderVisible(!isScrollingDown || currentScrollPos === 0);
            setPrevScrollPos(currentScrollPos);
        };

        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, [prevScrollPos]);

    let sessionMaxPoints = 0;
    if (session_data && session_data.max_points) {
        sessionMaxPoints = session_data.max_points;
    }

    let sectionName = '';
    if (session_data && session_data.section) {
        sectionName = session_data.section.name;
    }

    return (
        <div className={`game-top-bar ${headerVisible ? '' : 'top-bar-hidden'}`}>
            <div className='game-top-bar-content'>
                <div className='game-logo'>
                    <img className='img-fluid' src={defaultLogo} alt='Game logo' />
                </div>
                    {gameStarted && data && (
                        <div className='content-wrapper'>
                            <div className='top-bar-text'>
                                <span className='top-bar-game-name'>{data.name}</span>
                                <span>{sectionName}</span>
                            </div>
                            <div className='game-top-bar-info'>
                                <div className='game-top-bar-questions'>
                                    <span><strong>2</strong></span>
                                    <div className='vr'>
                                    </div>
                                    <span>20</span>
                                </div>
                                <div className='game-top-bar-points'>
                                    <span><strong>{animatedSessionPoints.toFixed(0)}</strong></span>
                                    <hr></hr>
                                    <span>{sessionMaxPoints}</span>
                                </div>
                            </div>
                        </div>
                    )}
            </div>
        </div>
    );
}

export default GameTopBar;