import {Slide, SlideElement} from "../../types/gameSession";
import {Language, translations} from "../../translations";
import React from "react";
import {Col, Container, Row} from "react-bootstrap";

type Props = {
  slide: Slide;
  lang: Language;
  handleAnswer: () => void;
};

const GameSlide = ({ slide, lang, handleAnswer }: Props) => {
  const LANG = translations[lang];

  const renderElement = (element:SlideElement) => {
    switch (element.type) {
      case 'text':
        return <div dangerouslySetInnerHTML={{__html: element.content}}></div>;
      case 'image':
        return <img src={element.content} alt={slide.title} />;
      case 'video':
        return <video src={element.content} controls />;
      default:
        return null;
    }
  };

  const renderSlideContent = () => {
    return slide.rows.map((row, index) => {
      return (
        <Row key={index} className='game-slide-row gy-2'>
          {row.columns.map((column, index) => {
            return (
              <Col key={index} xs={column.mobile} md={column.desktop}>
                {column.elements.map((element, index) => {
                  return (
                    <div key={index} className='game-slide-element'>
                      {renderElement(element)}
                    </div>
                  );
                })}
              </Col>
            );
          })}
        </Row>
      );
    });
  };

  return (
    <div className='game-container'>
      <div className='game-card-header'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <h1>{slide.title}</h1>
            </div>
          </div>
        </div>
      </div>
      <div className='game-card-body'>
        <Container fluid={true} className='game-slide-container'>
            {renderSlideContent()}
        </Container>
      </div>
      <div className='game-card-footer'>
        <div className='container-fluid'>
            <div className='row'>
              <div className='col-12 d-flex justify-content-end'>
                <button className='btn btn-primary game-btn' onClick={handleAnswer}>{LANG.continue}</button>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default GameSlide;