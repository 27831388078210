import { configureStore } from '@reduxjs/toolkit'
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import profileReducer from './slices/profile'
import gameReducer from './slices/game'
import gameSessionReducer from './slices/gameSession'

export const createStore = () => {
    return configureStore({
        reducer: {
            profile: profileReducer,
            game: gameReducer,
            gameSession: gameSessionReducer
        }
    });
}

export const store = createStore()
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export type RootState = ReturnType<typeof store.getState>
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector