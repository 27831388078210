import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../api";

export type ProfileState = {
    loaded: boolean
    error: string | null
    messages: string[]
    permissions: string[]
};

const initialState: ProfileState = {
    loaded: false,
    error: null,
    messages: [],
    permissions: [],
};

export const getProfile = createAsyncThunk(
    'profile/getProfile',
    async (_, thunkAPI) => {
        try {
            return await api.get('/api/profile/');
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

const slice = createSlice({
    name: 'profile',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getProfile.fulfilled, (state, action) => {
            state.loaded = true;
            state.error = null;
            state.permissions = action.payload.permissions;
        });
        builder.addCase(getProfile.rejected, (state, action) => {
            state.loaded = true;
            state.error = action.payload as string;
        });
    }
});

export default slice.reducer;