import {QuestionTypeSelect, QuestionSelectOption} from "../../types/gameSession";
import React from "react";
import {updateOptionSelected} from "../../app/slices/gameSession";
import {useAppDispatch} from "../../app/store";

type Props = {
    question: QuestionTypeSelect;
}

const QuestionSelect = ({ question }: Props) => {
    const dispatch = useAppDispatch();

    const handleSelectOptionClick = (option: QuestionSelectOption) => {
        dispatch(updateOptionSelected({
            optionIndex: option.index,
            selected: !option.selected
        }));
    };

    return (
        <div className='game-question-content row'>
            {question.options.map((option) => (
                <div
                    key={option.index}
                    className={`col-12 col-md-9 offset-md-3 question-option-select ${option.selected ? 'selected' : ''} ${option.points > 0 ? 'correct' : 'incorrect'}`}>
                    <div className='row mb-4'>
                        <div
                            className='col-9 col-md-8'
                            onClick={() => handleSelectOptionClick(option)}
                        >
                            <div className='question-option-wrapper'>
                                <span>{option.option}</span>
                            </div>
                        </div>
                        <div className='col-3 col-md-4'>
                            <div className='question-option-points'>
                                <span>{option.points}</span>
                            </div>
                        </div>
                    </div>
                </div>

            ))}
        </div>
    )
}

export default QuestionSelect;