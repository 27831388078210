import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {api} from "../api";
import {
    GameSession,
    QuestionTypeOrder,
    QuestionOrderOption,
    QuestionTypeSelect,
    QuestionTypeConnect
} from "../../types/gameSession";
import {updateGameTheme} from "./game";
import { RootState } from '../store'
import {FormField} from "../../types/games";

export type GameSessionState = {
    loaded: boolean
    error: string | null
    session_data?: GameSession
    fade_out: boolean
};

const initialState: GameSessionState = {
    loaded: false,
    error: null,
    session_data: undefined,
    fade_out: false
};

export type NewSessionPayload = {
    form_fields: FormField[] | undefined
    game_id: number
    language: string
}

export const startGameSession = createAsyncThunk(
    'gameSession/startGameSession',
    async (payload: NewSessionPayload, thunkAPI) => {
        try {
            const dispatch = thunkAPI.dispatch;
            const response = await api.post(`/API/games/sessions/`, payload);
            const gameTheme = response.theme;
            if (gameTheme) {
                dispatch(updateGameTheme(gameTheme));
            }
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


export type questionAnswerPayload = {
    content_id: number
    answers: number[]
}

export const continueGameSession = createAsyncThunk(
    'gameSession/continueGameSession',
    async (_, thunkAPI) => {
        try {
            const dispatch = thunkAPI.dispatch;
            const response = await api.get(`/API/games/sessions/`);
            const gameTheme = response.theme;
            const state = (thunkAPI.getState() as RootState).gameSession;
            const currentContentId = state.session_data?.content_id;
            if (currentContentId !== response.content_id) {
                dispatch(fadeOutContent());
                setTimeout(() => {
                    dispatch(loadNextContent(response));
                }, 500);
            }
            if (gameTheme) {
                dispatch(updateGameTheme(gameTheme));
            }

            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const questionAnswer = createAsyncThunk(
    'gameSession/questionAnswer',
    async (payload: questionAnswerPayload, thunkAPI) => {
        try {
            const dispatch = thunkAPI.dispatch;
            const response = await api.patch(`/API/games/sessions/`, payload);
            const gameTheme = response.theme;
            const state = (thunkAPI.getState() as RootState).gameSession;
            const currentContentId = state.session_data?.content_id;
            if (currentContentId !== response.content_id) {
                dispatch(fadeOutContent());
                setTimeout(() => {
                    dispatch(loadNextContent(response));
                }, 500);
            }else{
                dispatch(showExtraContent(response));
            }

            if (gameTheme) {
                dispatch(updateGameTheme(gameTheme));
            }

            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


const slice = createSlice({
    name: 'gameSession',
    initialState,
    reducers: {
        clearError: (state) => {
            state.error = null;
        },
        updateOrder: (state, action: PayloadAction<QuestionOrderOption[]>) => {
            if (state.session_data) {
                const question = state.session_data.question as QuestionTypeOrder;
                if (question && question.options) {
                    question.options = action.payload;
                }
            }
        },
        updateOptionSelected: (state, action: PayloadAction<{ optionIndex: number, selected: boolean }>) => {
            if (state.session_data) {
                const question = state.session_data.question as QuestionTypeSelect;
                if (question && question.options) {
                    const option = question.options.find(option => option.index === action.payload.optionIndex);
                    if (option) {
                        if (!question.multiple && action.payload.selected) {
                            // If multiple is false and an option is being selected, deselect all other options
                            question.options.forEach(opt => {
                                if (opt !== option) {
                                    opt.selected = false;
                                }
                            });
                        }
                        option.selected = action.payload.selected;
                    }
                }
            }
        },

        updateConnectSelectedPairs: (state, action: PayloadAction<{ selected_pairs: [number, number][] }>) => {
            const { selected_pairs } = action.payload;
            const question = state.session_data?.question as QuestionTypeConnect;

            if (question) {
                question.selected_pairs = selected_pairs;
            }
        },


        fadeOutContent: (state) => {
            if (state.session_data) {
                state.fade_out = true;
            }
        },

        showExtraContent: (state, action: PayloadAction<GameSession>) => {
            state.session_data = action.payload;
            state.fade_out = false;
        },

        loadNextContent: (state, action: PayloadAction<GameSession>) => {
            state.session_data = action.payload;
            state.fade_out = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(continueGameSession.fulfilled, (state, action) => {
            state.loaded = true;
            state.error = null;
        });
        builder.addCase(continueGameSession.rejected, (state, action) => {
            state.loaded = true;
            if (typeof action.payload === 'string') {
                state.error = action.payload as string;
            } else if (typeof action.error?.message === 'string') {
                state.error = action.error.message;
            }
        });
        builder.addCase(startGameSession.fulfilled, (state, action) => {
            state.session_data = action.payload;
            state.loaded = true;
            state.error = null;
        });
        builder.addCase(startGameSession.rejected, (state, action) => {
            state.loaded = true;
            state.error = action.payload as string;
        });
        builder.addCase(questionAnswer.pending, (state) => {
            state.error = null;
        });
        builder.addCase(questionAnswer.fulfilled, (state, action) => {
            // state.session_data = action.payload;
        });
        builder.addCase(questionAnswer.rejected, (state, action) => {
            state.loaded = true;
            if (typeof action.payload === 'string') {
                state.error = action.payload as string;
            } else if (typeof action.error?.message === 'string') {
                state.error = action.error.message;
            }
        });
    }
});

export const {
    fadeOutContent,
    loadNextContent,
    showExtraContent,
    updateOptionSelected,
    updateOrder,
    updateConnectSelectedPairs,
    clearError
} = slice.actions;
export default slice.reducer;