import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../app/store";
import Loader from "../Loader";
import {getGame} from "../../app/slices/game";
import {useParams} from "react-router-dom";
import GameSession from "./GameSession";
import { startGame } from "../../app/slices/game";
import GameStart from "./GameStart";
import GameTopBar from "./GameTopBar";
import toast from "react-hot-toast";
import {translations} from "../../translations";


const Game = () => {
    const dispatch = useAppDispatch();
    const { loaded,error,data, gameStarted, gameTheme, language } = useAppSelector((state) => state.game);
    const { slug } = useParams();

    const LANG = translations[language];

    useEffect(() => {
        // NOTE: on React.StrictMode, this useEffect is called twice on dev mode
        if (loaded) return;
        dispatch(getGame(slug));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loaded, slug]);


    if (data){
        document.title = 'Scope - ' + data.name;
    }

    // handle start game is a form submit
    const handleStartGame = () => {
        const formFields = data?.form;
        if (formFields) {
            const allFieldsFilled = formFields.every((field) => field.value !== '');
            if (!allFieldsFilled) {
                toast.error(LANG.fill_all_fields, {position: 'top-right',});
            }else{
                dispatch(startGame());
            }
        }
    }

    if (error) {
        return (
            <div className='game-theme-container'>
                <div className='game-card-container d-flex justify-content-center align-items-center'>
                    {LANG.error_loading_game}
                </div>
            </div>
        );
    }

    if (!loaded || !data) {
        return (
            <div className='game-theme-container'>
                <div className='game-card-container'>
                    <Loader />
                </div>
            </div>
        );
    }

    let themeStyle:React.CSSProperties = {};
    if (gameTheme && gameTheme.background_image && gameTheme.color) {
        themeStyle = {
            color: gameTheme.color,
            backgroundImage: `url(${gameTheme.background_image})`
        }
    }

    return (
        <div className={`game-theme-container ${gameStarted ? 'game-started' : ''}`} style={themeStyle}>
            <GameTopBar lang={language} gameStarted={gameStarted}/>
            <div className='game-card-container'>
                {data && gameStarted ? <GameSession game_id={data.id} lang={language} /> : <GameStart handleStart={handleStartGame} gameData={data} slug={slug} lang={language} />}
            </div>
        </div>
    )
}

export default Game;