import React from 'react';
import { Game as GameType } from "../../types/games";
import {Language, translations} from "../../translations";
import {useAppDispatch} from "../../app/store";
import {setFormData} from "../../app/slices/game";

type Props = {
    handleStart: () => void;
    gameData: GameType;
    slug: string | undefined;
    lang: Language;
}

const GameStart = ({ handleStart, gameData, slug, lang }: Props) => {
    const dispatch = useAppDispatch();
    const LANG = translations[lang];

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        dispatch(setFormData({ [name]: value }));
    }

    let languageOptions;
    if (gameData.languages.length > 0) {
        languageOptions = gameData.languages.map((lang) => {
            const selectedClass = lang.game.slug === slug ? 'selected-language' : '';
            return (
                <a className={selectedClass} key={lang.code} href={lang.game.slug}>{lang.name}</a>
            );
        });
    }


    let formFields;
    if (gameData.form.length > 0) {
        formFields = gameData.form.map((field) => {

            return (
                <div key={field.id} className='form-group col-12'>
                    <label className="form-label" htmlFor={field.name}>{field.label}</label>
                    <input className="form-control"
                           type={field.type}
                           id={field.name}
                           value={field.value}
                           name={field.name}
                           required={true}
                           onChange={handleInputChange} />
                </div>
            );
        });
    }

    // let sections;
    // if (gameData.sections.length > 0) {
    //     sections = gameData.sections.map((section) => {
    //         return (
    //             <li key={section.id}>{section.name}</li>
    //         );
    //     });
    // }

    return (
        <div className='game-start-screen'>
            <div className='game-card-header game-card-header-start'>
                <div className='container-fluid'>
                    {languageOptions &&
                    <div className='game-card-header-top'>
                        {languageOptions}
                    </div>
                    }
                    <div className='row'>
                        <div className='col-4 col-md-3'>
                            <img src='https://placehold.co/500x500/5c7587/fff' alt={gameData.name} className='game-thumbnail img-fluid' />
                        </div>
                        <div className='col-8 col-md-6'>
                            <div className='game-start-description ingress pt-lg-5'>
                                <h1>{gameData.name}</h1>
                                <div dangerouslySetInnerHTML={{__html:gameData.description}}></div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className='game-card-body'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12 col-md-6 offset-md-3'>
                            {formFields &&
                            <form onSubmit={handleStart} id='game-start' className='row pb-3 gy-4' action='' method='POST'>
                                {formFields}
                            </form>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='game-card-footer'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12 d-flex justify-content-end'>
                            <button type='submit' form='game-start' className='btn btn-primary game-btn'
                                    onClick={handleStart}>{LANG.start}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GameStart;