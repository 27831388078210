import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import { api } from "../api";
import {Game, Theme} from "../../types/games";
import {Language} from "../../translations";

export type GameState = {
    loaded: boolean
    error: string | null
    gameStarted: boolean
    gameTheme?: Theme
    data?: Game
    language: Language
};

const initialState: GameState = {
    loaded: false,
    error: null,
    gameStarted: false,
    gameTheme: undefined,
    data: undefined,
    language: 'fi'
};

export const getGame = createAsyncThunk(
    'game/getGame',
    async (gameSlug:string | undefined, thunkAPI) => {
        try {
            return await api.get(`/API/games/slug/${gameSlug}/`);
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


const slice = createSlice({
    name: 'game',
    initialState,
    reducers: {
        setFormData(state, action) {
            if (state.data) {
                state.data.form.forEach((field) => {
                    if (field.name in action.payload) {
                        field.value = action.payload[field.name];
                    }
                });
            }
        },
        startGame(state) {
            state.gameStarted = true;
        },
        updateGameTheme(state, action) {
            if (state.gameTheme && state.gameTheme.id === action.payload.id) {
                return;
            }else{
                state.gameTheme = action.payload;
            }
        }

    },
    extraReducers: (builder) => {
        builder.addCase(getGame.fulfilled, (state, action) => {
            state.loaded = true;
            state.error = null;
            state.data = action.payload;
            state.gameTheme = action.payload.theme;
            // force language as uppercase
            state.language = action.payload.language;
        });
        builder.addCase(getGame.rejected, (state, action) => {
            state.loaded = true;
            state.error = action.payload as string;
        });
    }
});

export const { startGame, updateGameTheme, setFormData } = slice.actions;
export default slice.reducer;