import React from 'react';
import {QuestionTypeOrder} from "../../types/gameSession";
import {useAppDispatch} from "../../app/store";
import {updateOrder} from "../../app/slices/gameSession";
import {DragDropContext, Draggable, Droppable, DropResult} from "@hello-pangea/dnd";
import { ReactComponent as DragIcon } from '../../images/icons/grip-dots-vertical-solid.svg';


type Props = {
    question: QuestionTypeOrder;
}

const QuestionOrder = ({ question }: Props) => {
    const dispatch = useAppDispatch();


    const handleDragEnd = (result: DropResult) => {
        if (!result.destination) return;
        const reorderedOptions = [...(question as QuestionTypeOrder).options];
        const [removed] = reorderedOptions.splice(result.source.index, 1);
        reorderedOptions.splice(result.destination.index, 0, removed);
        dispatch(updateOrder(reorderedOptions));
    }

    return (
        <div className='game-question-content row'>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className={`col-12 col-md-9 offset-md-3 question-option-order ${snapshot.isDraggingOver ? 'dragging-over' : ''}`}
                        >
                            {(question as QuestionTypeOrder).options.map((option, index) => (
                                <Draggable key={option.index} draggableId={String(option.index)} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className={`question-option-order row mb-4 ${snapshot.isDragging ? 'dragging' : ''} ${option.ordering === index + 1 ? 'correct' : 'incorrect'}`}
                                        >
                                            <div className='col-9 col-md-8'>
                                                <div className='question-option-wrapper'>
                                                    <span>{option.option}</span>
                                                </div>
                                            </div>
                                            <div className='col-3 col-md-4'>
                                                <div className='option-drag-handle'>
                                                    {!question.answered ? <DragIcon /> : <span>{option.ordering}.</span> }
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    )
}

export default QuestionOrder;