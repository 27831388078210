import React, { useState } from "react";
import { QuestionConnectOption, QuestionTypeConnect } from "../../types/gameSession";
import { useAppDispatch } from "../../app/store";
import { Language, translations } from "../../translations";
import { Modal } from "react-bootstrap";
import {updateConnectSelectedPairs} from "../../app/slices/gameSession";
import { ReactComponent as EditIcon } from '../../images/icons/pen-to-square-regular.svg';


type Props = {
  question: QuestionTypeConnect;
  lang: Language;
};

const QuestionConnect = ({ question, lang }: Props) => {
  const LANG = translations[lang];
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState<QuestionConnectOption | null>(null);

  const dispatch = useAppDispatch();

  const handleOptionSelect = (option: QuestionConnectOption) => {
    setSelectedOption(option);
    setShowModal(true);
  };

  const getSelectedPair = (option1: number) => {
    if (question.selected_pairs) {
      const pair = question.selected_pairs.find(pair => pair[0] === option1);
      if (pair) {
        return pair[1];
      }
    }
    return null;
  };

  const handlePairSelect = (option2: number) => {
    if (selectedOption) {
      let updatedPairs: [number, number][] = [];
      if (question.selected_pairs) {
        updatedPairs = question.selected_pairs.filter(pair => {
          return !(pair[0] === selectedOption.index || pair[1] === option2);
        });
      }
      // Add the new pairing
      updatedPairs.push([selectedOption.index, option2]);
      dispatch(updateConnectSelectedPairs({ selected_pairs: updatedPairs }));
    }
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setSelectedOption(null);
    setShowModal(false);
  };

  const pairedOptionIndex = selectedOption ? getSelectedPair(selectedOption.index) : null;

  const secondaryOptions = question.options2.map((option2) => (
    <div
      key={option2.index}
      className={`modal-option ${pairedOptionIndex === option2.index ? 'paired' : ''}`}
      onClick={() => handlePairSelect(option2.index)}
    >
      <div className="modal-option-wrapper">{option2.option}</div>
    </div>
  ));

  return (
    <div className="game-question-content row">
      {question.options1.map((option1, index) => (
          <div key={option1.index} className={`col-12 col-md-6 offset-md-3 question-option-connect ${question.answered && option1.correct ? 'correct' : 'incorrect'}`}>
              <div className='row mb-4'>
                <div className='col-12 col-md-6 d-flex align-items-center'>
                    <div className='question-primary'>
                        <span>{option1.option}</span>
                    </div>
                </div>
                <div className='col-12 col-md-6 d-flex align-items-center'>
                  <div
                      className={`question-secondary`}
                      onClick={() => handleOptionSelect(option1)}
                  >
                    <div className="input-group">
                      <div
                          className={`form-control input-text ${getSelectedPair(option1.index) !== null ? 'text-start' : ''}`}>
                        {getSelectedPair(option1.index) !== null ?
                            question.options2.find(option => option.index === getSelectedPair(option1.index))?.option :
                            LANG.choose_answer
                        }
                      </div>

                      {getSelectedPair(option1.index) !== null && !question.answered && (
                          <div className="input-group-prepend">
                            <div className="input-group-text"><EditIcon/></div>
                          </div>
                      )}
                    </div>

                  </div>
                </div>
              </div>
          </div>
      ))}
      <Modal centered backdrop="static" className='connect-modal' show={showModal} animation={true}
             onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedOption?.option}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{secondaryOptions}</Modal.Body>
        <Modal.Footer>
          <button className='btn game-btn btn-light-primary' onClick={handleCloseModal}>{LANG.close}</button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default QuestionConnect;