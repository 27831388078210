import React from 'react';
import {Card} from "react-bootstrap";

function App() {
  return (
    <div className="window-container justify-content-center align-items-center">
        <Card style={{width: '18rem'}}>
          <Card.Body>
            <Card.Title>Nothing here but darkness</Card.Title>
            <Card.Link href="/admin">Admin</Card.Link>
            <Card.Link href="/games">Games</Card.Link>
          </Card.Body>
        </Card>
    </div>
  );
}

export default App;
