import React from 'react';
import {Card} from "react-bootstrap";

const Games = () => {
    return (
        <div className="window-container justify-content-center align-items-center">
            <Card style={{width: '18rem'}}>
                <Card.Body>
                    <Card.Title>Are we going to have a game index?</Card.Title>
                    <Card.Link href="/admin">Admin</Card.Link>
                    <Card.Link href="/games/test">Single test game</Card.Link>
                </Card.Body>
            </Card>
        </div>
    )
}

export default Games;