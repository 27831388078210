import React, {useEffect, useState} from 'react';
import defaultLogo from "../../images/perjantai-logo.svg";


const AdminTopBar = () => {
    const [headerVisible, setHeaderVisible] = useState(true);
    const [prevScrollPos, setPrevScrollPos] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            const isScrollingDown = currentScrollPos > prevScrollPos;
            setHeaderVisible(!isScrollingDown || currentScrollPos === 0);
            setPrevScrollPos(currentScrollPos);
        };

        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, [prevScrollPos]);

    return (
        <div className={`admin-top-bar d-flex justify-content-between ${headerVisible ? '' : 'top-bar-hidden'}`}>
            <div>
                lorem
            </div>
            <div className='media d-flex'>
                Matti Meikäläinen
            </div>
        </div>
    )
}

export default AdminTopBar;