import React from 'react';
import {
    QuestionTypeOrder,
    QuestionTypeSelect,
    QuestionTypeConnect,
    BaseQuestion
} from "../../types/gameSession";
import QuestionOrder from "./QuestionOrder";
import QuestionConnect from "./QuestionConnect";
import {Language} from "../../translations";
import QuestionSelect from "./QuestionSelect";

type Props = {
    question: QuestionTypeSelect | QuestionTypeOrder | QuestionTypeConnect;
    lang: Language;
}

const QuestionContent = ({ question, lang }: Props) => {
    function isQuestionTypeOrder(question: BaseQuestion): question is QuestionTypeOrder {
      return question.type === 'order';
    }
    function isQuestionTypeConnect(question: BaseQuestion): question is QuestionTypeConnect {
      return question.type === 'connect';
    }

    let content;

    switch (question.type) {
        case 'select':
            const selectQuestion = question as QuestionTypeSelect;
            content = <QuestionSelect question={selectQuestion} />;
            break;
        case 'order':
            if (isQuestionTypeOrder(question)) {
                content = <QuestionOrder question={question} />;
            }
            break;
        case 'connect':
            if (isQuestionTypeConnect(question)) {
                content = <QuestionConnect question={question} lang={lang} />;
            }
            break;
        default:
            content = <div>Unknown question type</div>;
            break;
    }

    return (
        <div className='game-question-content'>
            <div className='container-fluid'>
                {content}
            </div>
        </div>
    )
}

export default QuestionContent;