import React from "react";
import { BrowserRouter, useRoutes } from "react-router-dom";
import Admin from "./components/admin/Admin";
import Games from "./components/games/Games";
import App from "./App";
import Game from "./components/games/Game";

const Root = () => {
    const routes = [
        { path: '/', element: <App /> },
        { path: '/admin', element: <Admin /> },
        { path: '/games', element: <Games /> },
        { path: '/games/:slug', element: <Game /> },
    ];
    return useRoutes(routes);
};

const AppRoutes = () => {

    return (
        <BrowserRouter>
            <Root />
        </BrowserRouter>
    )
}

export default AppRoutes;